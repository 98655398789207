// Screen sizes
$desktop: 1200px;
$laptop: 992px;
$tablet: 768px;
$mobile: 576px;

$header-height: 64px;
$mobile-header-height: 56px;
$footer-height: 60px;

// Colors
$primary: #cd333e;
$secondary: #000000;
$tertiary: #b3b3b3;
$error: #ea0000;

:export {
  desktop: $desktop;
  laptop: $laptop;
  tablet: $tablet;
  mobile: $mobile;
  headerHeight: $header-height;
  mobileHeaderHeight: $mobile-header-height;
  footerHeight: $footer-height;
  primary: $primary;
  secondary: $secondary;
  tertiary: $tertiary;
  error: $error;
}
