@import '../../styles/variables.scss';

.app-bar__container {
  height: 64px;

  .app-bar {
    min-height: 64px;
    justify-content: space-between;
    background-color: #fff;

    .app-bar__personal {
      display: flex;
      align-items: center;
  
      .app-bar__logo {
        height: 48px;
        margin-right: 15px;
      }
    }

    .app-bar__mobile-menu-icon {
      color: #000;
    }

    .app-bar__menu-button {
      color: #000;

      .app-bar__menu-button-link {
        color: #000;
        text-decoration: none;
      }
    }
  }
}

@media (min-width: $tablet) {
  .app-bar__container {

    .app-bar__menu-button-container {
      display: flex;
      justify-content: center;
    }
  }
}

@media (max-width: ($tablet - 1)) {

}