@import '../styles/variables.scss';

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #eaeaea;
  
  &:not(.no-margin) {
    margin: 0 auto;
    padding: 15px;
  }

  .page-title {
    text-align: center;
  }

  h2 {
    color: $primary;
  }
}
