@import '../../styles/variables.scss';

#footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  background-color: #fff;
  box-shadow: 0px 0px 4px 1px rgb(0, 0, 0, 0.4);

  .footer__container {
    padding: 0 30px;

    .footer__item {
      display: flex;
      color: #fff;
    }

    .footer__copyright {
      justify-content: flex-end;
      margin: 15px 0;
      color: #000;
      font-size: 0.75rem;
      font-weight: 600;
    }
  }
}

@media (min-width: $tablet) {
  #footer {
    padding: 15px;
    
    .footer__container {
      .footer__copyright {
        text-align: end;
      }
    }
  }
}

@media (max-width: ($tablet - 1)) {
  #footer {
    .footer__container {
      .footer__copyright {
        text-align: center;
      }
    }
  }
}

@media (max-width: ($mobile - 1)) {
  #footer {
    .footer__container {
      .footer__copyright {
        margin-bottom: 50px;
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}