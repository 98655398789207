@import '../../styles/variables.scss';

.contact__content {
  max-width: $laptop;
}

.contact__info {
  text-align: left;
  max-width: $mobile;
}
