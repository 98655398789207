@import '../../styles/variables.scss';

.mobile-menu {
  .mobile-menu__menu-button-container {
    display: flex;
    flex-direction: column;
    min-width: 200px;

    .mobile-menu__menu-button-link {
      padding: 10px 5px;
      font-size: 1rem;
      font-weight: 600;
      color: #000;
      text-transform: none;
      text-decoration: none;
    }
  }
}